<template>
  <RightSideBar
    v-if="isOpen"
    @close="isOpen = false"
    :loading="isLoading"
    close="Cancel"
    close-button
  >
    <template v-slot:title>
      <p class="text-darkPurple font-bold text-lg leading-5 p-0">View</p>
    </template>
    <template v-slot:subtitle>
      <Card
        class="flex justify-start items-center gap-2 p-2 mb-5"
        v-if="payload.length > 0"
      >
        <div>
          <img
            class="mr-3"
            :src="payload[0].photo"
            v-if="payload[0].photo"
            alt="user photo"
            style="height: 40px; width: 40px; border-radius: 5px"
          />
          <div
            style="height: 40px; width: 40px; border-radius: 5px"
            class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
            v-else
          >
            {{ $getInitials(`${payload[0].name}`) }}
          </div>
        </div>
        <div class="flex flex-col items-start font-semibold">
          <span
            class="text-optima capitalize font-semibold"
            style="font-size: 10px"
          >
            employee
          </span>
          <span class="text-darkPurple uppercase" style="font-size: 10px">
            {{ payload[0].name }}
          </span>
          <span class="text-romanSilver uppercase" style="font-size: 10px">
            {{ payload[0].designation }}
          </span>
        </div>
      </Card>
    </template>
    <template v-if="payload.length > 0">
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2 w-full">
          <div class="flex justify-between items-center w-full">
            <div class="flex justify-start items-center gap-2 w-1/2">
              <p class="font-normal text-sm text-jet">Appraisal Score</p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].appraisalScore"
                :disabled="true"
              />
            </div>
            <div class="flex justify-end items-center gap-2 w-1/2">
              <p class="font-normal text-sm text-jet">Team Average</p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].teamAverage"
                :disabled="true"
              />
            </div>
          </div>
          <div class="flex justify-between items-center w-full">
            <div class="flex justify-start items-center gap-2 w-1/2">
              <p class="w-24 mr-1 font-normal text-sm text-jet">
                Organisation Average
              </p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].orgAverageScore"
                :disabled="true"
              />
            </div>
            <div class="flex justify-end items-center gap-2 w-1/2">
              <p class="w-24 mr-1 pl-3 font-normal text-sm text-jet">
                Functional Moderation
              </p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].averageModScore"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <template>
          <div class="flex flex-col w-full gap-2 mt-5">
            <div
              class="pb-4 leading-5 p-0 flex justify-between items-center w-full"
            >
              <p class="text-darkPurple font-bold text-base leading-5">
                Moderation Details
              </p>
              <div
                class="w-8 h-8 cursor-pointer flex justify-end items-center"
                @click="showDetails = !showDetails"
              >
                <Icon
                  class-name="text-darkPurple"
                  size="xs"
                  class="w-8 h-8"
                  :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                />
              </div>
            </div>
            <template v-if="showDetails">
              <div class="flex flex-col justify-start gap-2">
                <Card
                  class="w-full py-2 px-4 flex justify-between items-end shadow rounded-md"
                  noborder
                  v-for="(moderators, index) in payload[0].moderators
                    .moderators"
                  :key="index"
                >
                  <div class="flex justify-start items-start gap-0 w-1/2">
                    <Icon
                      class-name="text-romanSilver self-start pt-1"
                      size="xs"
                      icon-name="icon-manager"
                    />
                    <div class="flex flex-col justify-start items-start">
                      <p class="text-romanSilver text-xs leading-5 font-normal">
                        {{
                          moderators.sequence === 0
                            ? "Line Manager"
                            : `Moderator${index}`
                        }}
                      </p>
                      <p
                        class="text-jet uppercase text-xs leading-5 font-semibold"
                      >
                        {{ moderators.fname }} {{ moderators.lname }}
                      </p>
                    </div>
                  </div>
                  <div class="flex justify-between items-center w-1/2">
                    <p
                      class="text-jet uppercase text-xs leading-5 font-semibold"
                    >
                      Weight: {{ moderators.weight }}%
                    </p>
                    <p class="text-jet uppercase text-xs leading-5 font-black">
                      {{ moderators.score }}
                    </p>
                  </div>
                </Card>
              </div>
            </template>
            <Card class="w-full rounded-md" norborder>
              <div
                class="py-2 px-3 flex justify-between items-center w-full bg-cultured"
              >
                <span class="font-semibold text-xs leading-5 text-jet">
                  Weighted Average
                </span>
                <span class="font-bold text-lg leading-5 text-flame">
                  {{ payload[0].moderators.average }}
                </span>
              </div>
            </Card>
          </div>
        </template>

        <div class="flex justify-between items-center gap-5 my-5">
          <p class="text-sm font-normal text-jet">Final (CEO’s) Moderation</p>
          <TextInput
            class="text-base text-darkPurple border-class"
            placeholder="Enter"
            :value="payload[0].ceoScore"
            :disabled="true"
          />
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import RightSideBar from "@/components/RightSideBar";
import Icon from "@/components/Icon";

export default {
  components: {
    Card,
    TextInput,
    RightSideBar,
    Icon,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      showDetails: true,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
.border-class input {
  border: 1px solid !important;
}
</style>