<template>
  <div class="w-full pt-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex mx-2">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">
          Moderation Details
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>

    <template v-if="enabledModeration">
    <template v-if="isLoading">
      <div class="h-full mt-20" v-if="isLoading">
        <Loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <template v-if="startAgreement || endAgreement">
        <template>
          <Card class="p-5 flex flex-col m-5 gap-4">
            <template>
              <div class="flex justify-between items-center">
                <span
                  class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
                >
                  {{ agreementData.title }}
                </span>
              </div>
            </template>
            <template>
              <div
                class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
              >
                <div
                  class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                  @click="showDetails = !showDetails"
                >
                  <span
                    class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                  >
                    Performance Details
                  </span>
                  <span
                    class="w-9/12 border-t border-solid border-romanSilver h-0"
                  />
                  <Icon
                    :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                    class-name="text-darkPurple mx-auto self-center"
                    size="xms"
                  />
                </div>
                <div
                  class="flex flex-col gap-2"
                  :class="{ hidden: !showDetails }"
                >
                  <div class="flex p-3 shadow rounded-md gap-2">
                    <Icon
                      size="s"
                      icon-name="doc-cycle"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                      >
                        {{ agreementData.cycle }}
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{ agreementData.description }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                    >
                      <Icon
                        size="xs"
                        icon-name="calendar"
                        class-name="text-romanSilver self-start pt-1"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                        >
                          Period
                        </span>
                        <span
                          class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.start),
                              "MMMM dd, yyyy"
                            )
                          }}
                          -
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.end),
                              "MMMM dd, yyyy"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                      v-for="(cycle, index) in agreementCycles"
                      :key="index"
                    >
                      <Icon
                        class-name="text-romanSilver self-start pt-1"
                        size="xs"
                        icon-name="reviewicon"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                        >
                          {{ cycle.name }}
                        </span>
                        <span
                          class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                          }}
                          -
                          {{
                            $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy")
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template>
              <div class="flex justify-between items-center">
                <div class="w-3/7 flex justify-start gap-2">
                  <div
                    class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-0"
                      size="xs"
                      icon-name="toolbox"
                    />
                    <div class="flex flex-col gap-0">
                      <span
                        class="font-black text-xs leading-5 uppercase text-darkPurple"
                      >
                        Organization Score
                      </span>
                      <span class="font-semibold text-sm leading-5 uppercase">
                        {{ orgScore }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-0"
                      size="xs"
                      icon-name="toolbox"
                    />
                    <div class="flex flex-col gap-0">
                      <span
                        class="font-black text-xs leading-5 uppercase text-darkPurple"
                      >
                        Average Employee Score
                      </span>
                      <span class="font-semibold text-sm leading-5 uppercase">
                        {{ averagePreScore }}
                      </span>
                    </div>
                  </div>
                </div>
                <template>
                  <div
                    class="w-4/7 flex flex-wrap justify-end items-center gap-5"
                  >
                    <p class="font-normal text-base text-darkPurple">
                      Reduce or Increase multiple scores at once
                    </p>
                    <Button
                      label="Bulk Moderation"
                      variant="primary button-class whitespace-nowrap"
                      @onClick="toggleCeoBulkModeration"
                    />
                  </div>
                </template>
              </div>
            </template>
          </Card>
        </template>
        <template>
          <div class="w-full px-5 my-5">
            <Card class="p-5 pt-0">
              <CardFooter reloadcard />
            </Card>
          </div>
        </template>
        <template>
          <div class="mx-5">
            <Table
              :headers="headers"
              :items="items"
              :has-number="false"
              :loading="isLoading"
              style="width: 100%"
            >
              <template v-slot:item="{ item }">
                <div
                  v-if="item.appraisee"
                  class="flex justify-start items-start gap-2"
                >
                  <div class="flex">
                    <img
                      :src="item.data.photo"
                      alt="user photo"
                      v-if="item.photo"
                      class="w-8 h-8"
                    />
                    <div
                      class="text-blueCrayola w-8 h-8 flex justify-center border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${item.data.appraisee.name}`) }}
                    </div>
                  </div>
                  <div
                    v-if="item.data.appraisee"
                    class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                  >
                    <span
                      class="text-darkPurple capitalize"
                      v-if="item.data.appraisee"
                    >
                      {{ item.data.appraisee.name }}
                    </span>
                    <span
                      class="text-carrotOrange uppercase text-xs"
                      v-if="item.data.appraisee"
                    >
                      {{ item.data.appraisee.position }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="item.functions"
                  class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                >
                  <span class="text-darkPurple capitalize">{{
                    item.data.functions.department
                  }}</span>
                  <span class="text-optima text-xs uppercase">{{
                    item.data.functions.designation
                  }}</span>
                </div>
                <div v-if="item.location" class="flex whitespace-nowrap">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.location }}
                  </span>
                </div>
                <div v-if="item.appraisalScore" class="flex whitespace-nowrap">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.appraisalScore }}
                  </span>
                </div>
                <div v-if="item.fnModScore" class="flex whitespace-nowrap">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.fnModScore }}
                  </span>
                </div>
                <div v-if="item.ceoModScore" class="flex whitespace-nowrap">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.ceoModScore }}
                  </span>
                </div>
                <div
                  v-if="item.difference"
                  class="flex justify-start items-center gap-2"
                >
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.difference.score }}
                  </span>
                  <div
                    class="py-1 px-2 rounded-md flex gap-1"
                    :class="{
                      'bg-red-200 text-desire':
                        item.data.difference.status === 'decreasing',
                      'bg-green-200 text-mediumSeaGreen':
                        item.data.difference.status === 'increasing',
                      'bg-gray-200 text-romanSilver':
                        item.data.difference.status === 'default',
                    }"
                    v-if="item.data.ceoHasDone"
                  >
                    <Icon
                      class-name="text-mediumSeaGreen"
                      size="xs"
                      :icon-name="
                        item.data.difference.status === 'increasing'
                          ? 'icon-trending-up-green'
                          : item.data.difference.status === 'decreasing'
                          ? 'icon-trending-down'
                          : 'icon-dashed'
                      "
                    />
                    <span>{{ item.data.difference.value }}</span>
                  </div>
                </div>
                <div v-if="item.performanceBand">
                  <span class="text-sm leading-6 font-normal text-darkPurple">
                    {{ item.data.performanceBand }}
                  </span>
                </div>
                <div v-if="item.id" class="cursor-pointer">
                  <Menu left @click.stop="" class="">
                    <template v-slot:title>
                      <Icon icon-name="more_icon" size="xs" />
                    </template>
                    <template>
                      <div
                        class="flex flex-col w-38 h-18 p-2 justify-start items-start"
                      >
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md"
                          @click="
                            toggleCeoModerationView(
                              item.data.id,
                              item.data.fnModScore
                            )
                          "
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                          >
                            <Icon
                              icon-name="icon-eye"
                              class-name="text-blueCrayola"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              View
                            </span>
                          </div>
                        </div>
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md"
                          @click="
                            toggleCeoModerateModeration(
                              item.data.id,
                              item.data.fnModScore
                            )
                          "
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                          >
                            <Icon
                              icon-name="maximize"
                              class-name="text-carrotOrange"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              Moderate
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </Menu>
                </div>
              </template>
            </Table>
          </div>
        </template>
        <SubmitBulkModeration
          ref="bulkModeration"
          :payload="items"
          @close="reloadData($event)"
        />
        <CeoModerationView
          ref="ceoModerationView"
          :payload="individualModeration"
          :is-loading="modalLoading"
        />
        <CeoModerateModeration
          ref="ceoModerate"
          :payload="individualModeration"
          :is-loading="modalLoading"
          @close="reloadData($event)"
        />
      </template>
      <template v-else>
        <div
          class="flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full"
        >
          <Icon size="" class-name="w-80 h-80" icon-name="illustration" />
          <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
            Moderation period starts on
            {{ $DATEFORMAT(new Date(agreementOpenDate), "MMMM dd, yyyy") }}
            and ends on
            {{ $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy") }}
          </p>
        </div>
      </template>
    </template>
    </template>

      <template v-else>
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No Performance Moderation set up for this Template.
          </div>
        </div>
      </template>

  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Button from "@scelloo/cloudenly-ui/src/components/button";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import Icon from "@/components/Icon";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import SubmitBulkModeration from "./Modals/SubmitBulkModeration";
import CeoModerationView from "./Modals/CeoModerationView";
import CeoModerateModeration from "./Modals/CeoModerateModeration";

export default {
  mixins: [timeFormatMixin],
  components: {
    Table,
    Breadcrumb,
    BackButton,
    Card,
    Menu,
    Icon,
    CardFooter,
    Button,
    SubmitBulkModeration,
    CeoModerationView,
    CeoModerateModeration,
  },
  data() {
    return {
      showDetails: false,
      isLoading: true,
      modalLoading: true,
      orgScore: 0,
      averagePreScore: 0,
      averagePostScore: 0,
      enabledModeration: true,

      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Moderation",
          href: "EssModeration",
          id: "PerformanceModeration",
        },
        {
          disabled: false,
          text: "CeoModeration",
          href: "ceo-moderation",
          id: "CeoModeration",
        },
      ],
      headers: [
        { title: "Appraisee", value: "appraisee" },
        { title: "Function", value: "functions" },
        { title: "Location", value: "location" },
        { title: "Appraisal Score", value: "appraisalScore" },
        { title: "Functional Mod. Score", value: "fnModScore" },
        { title: "CEO Moderated  Score", value: "ceoModScore" },
        { title: "Difference", value: "difference" },
        { title: "performance Band", value: "performanceBand" },
        { title: "", value: "id", image: true },
      ],
      items: [],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      agreementCycles: [],
      startAgreement: false,
      endAgreement: false,
      agreementOpenDate: "",
      agreementCloseDate: "",
      individualModeration: [],
    };
  },
  methods: {
    toggleCeoBulkModeration() {
      if (this.endAgreement) {
        this.$toasted.error(`Moderation period ended on
              ${this.$DATEFORMAT(
                new Date(this.agreementCloseDate),
                "MMMM dd, yyyy"
              )}`);
      } else {
        this.$refs.bulkModeration.toggle();
      }
    },
    toggleCeoModerationView(moderationId, moderationScore) {
      this.individualModeration = [];
      this.modalLoading = true;

      this.$_singleModeration(moderationId).then((response) => {
        this.individualModeration.push({
          appraisalScore: response.data.data.moderation.appraisalScore,
          averageModScore: moderationScore,
          orgAverageScore: this.orgScore,
          teamAverage: this.averagePreScore,
          photo: response.data.data.photo,
          name: `${response.data.data.fname} ${response.data.data.lname}`,
          designation: response.data.data.designation,
          userId: response.data.data.userId,
          moderationAvg: response.data.data.moderators.average,
          moderators: response.data.data.moderators,
          ceoScore: response.data.data.moderation.ceoModerationScore,
        });

        this.modalLoading = false;
      });
      this.$refs.ceoModerationView.toggle();
    },

    toggleCeoModerateModeration(moderationId, moderationScore) {
      this.individualModeration = [];
      this.modalLoading = true;

      this.$_singleModeration(moderationId).then((response) => {
        this.individualModeration.push({
          appraisalScore: response.data.data.moderation.appraisalScore,
          averageModScore: moderationScore,
          orgAverageScore: this.orgScore,
          teamAverage: this.averagePreScore,
          photo: response.data.data.photo,
          name: `${response.data.data.fname} ${response.data.data.lname}`,
          designation: response.data.data.designation,
          userId: response.data.data.userId,
          moderationAvg: response.data.data.moderators.average,
          moderators: response.data.data.moderators,
          ceoScore: response.data.data.moderation.ceoModerationScore,
          moderationId,
        });

        this.modalLoading = false;
      });

      this.$refs.ceoModerate.toggle();
    },

    getAgreement() {
      this.$_getOrgPerformanceAgreement().then((response) => {
        const result = response.data.data;
        result.forEach((info) => {
          if (info.agreementDetail.year === Number(this.$route.params.year)) {
            this.agreementData.title =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.cycle =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.description =
              info.agreementDetail.goal_performance_templates.agreement_description;
            this.agreementData.period.start = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .start
            ).toISOString();
            this.agreementData.period.end = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .end
            ).toISOString();
            this.loading = false;
          }
        });
      });
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle().then((response) => {
        const appraisal = response.data.AppraisalCycle;
        this.agreementData.description = appraisal[0].description;
        if (appraisal[0].cycles.length > 0) {
          appraisal[0].cycles.map((cycle) => {
            this.agreementCycles.push({
              name: cycle.name,
              start: (this.setDates(cycle.appraisal_starts)).toISOString(),
              end: (this.setDates(cycle.appraisal_ends)).toISOString(),
            });
            return {};
          });
        }
      });
    },

    ceoModeration() {
      this.$_getCEOModeration(this.$route.params.year).then((response) => {
        const moderationArr = response.data.moderations;

        this.orgScore = response.data.orgAppraisalScore;
        this.averagePreScore = response.data.averageEmployeeScore;

        moderationArr.forEach((data) => {
          this.items.push({
            appraisee: {
              name: `${data.user.fname} ${data.user.lname}`,
              position: "",
              userId: data.user.userId,
            },
            functions: {
              department: `${data.user.function}`,
              designation: `${data.user.designation}`,
            },
            location: `${data.user.location}`,
            appraisalScore: `${data.moderation.appraisalScore}`,
            fnModScore: `${data.moderation.averageModerationScore}`,
            ceoModScore: `${
              !data.moderation.ceoDone
                ? "---"
                : data.moderation.ceoModerationScore
            }`,
            ceoHasDone: `${data.moderation.ceoDone}`,
            difference: {
              score: `${
                !data.moderation.ceoDone ? "---" : data.moderation.difference
              }`,
              status: `${
                data.moderation.difference > 0 ? "increasing" : "decreasing"
              }`,
              value: `${!data.moderation.ceoDone ? "" : data.ceoDifference}`,
            },
            performanceBand: `${
              data.moderation.ceoDone
                ? data.ceofunctionalPerformanceBand
                : "---"
            }`,
            photo: `${data.user.photo}`,
            id: `${data.id}`,
          });
        });

        this.isLoading = false;
      });
    },

    getTemplate() {
      this.$_getOneTemplate(this.$route.params.goalId).then((response) => {
        const template = response.data.PerformanceTemplate;

        if(template.enable_moderation) {
        const agreementStartDate = this.setDates(template.ceo_moderation_start);
        const agreementEndDate = this.setDates(template.ceo_moderation_end);

        this.agreementOpenDate = agreementStartDate.toISOString();
        this.agreementCloseDate = agreementEndDate.toISOString();

        const today = new Date();

        if (today >= agreementStartDate && today <= agreementEndDate) {
          this.startAgreement = true;
          this.endAgreement = false;
          this.getAgreement();
          this.getAppraisalCycles();
          this.ceoModeration();
        }
        if (today > agreementStartDate && today > agreementEndDate) {
          this.startAgreement = false;
          this.endAgreement = true;
          this.getAgreement();
          this.getAppraisalCycles();
          this.ceoModeration();
        }
        if (today < agreementStartDate && today < agreementEndDate) {
          this.startAgreement = false;
          this.endAgreement = false;
        }
        } else {
          this.isLoading = false;
          this.enabledModeration = false;
        }
      });
    },

    reloadData(val) {
      if (val) {
        this.isLoading = true;
        this.items = [];
        this.individualModeration = [];
        this.agreementCycles = [];
        this.getTemplate();
      }
    },
  },

  mounted() {
    this.getTemplate();
  },
};
</script>

<style scoped>
.button-class {
  padding: 15px 40px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.text-10px {
  font-size: 10px;
}
</style>