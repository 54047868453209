<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit, errors }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(submit)"
      @close="isOpen = false"
      submit="Send"
      :loading="isLoading"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0">
          Bulk Moderation
        </span>
      </template>
      <template v-slot:subtitle>
        <div class="pb-5 flex flex-col gap-5">
          <div
            class="flex p-2"
            style="
              background-color: rgba(244, 213, 176, 0.08);
              border: 1px solid #e99323;
              border-radius: 5px;
            "
          >
            <Icon
              class-name="text-carrotOrange mx-auto self-center"
              size="s"
              icon-name="icon-info"
            />
            <span class="flex text-sm mx-3">
              Moderate multiple employees’ score at once, by increasing or
              reducing by a common number.
            </span>
            <Icon
              size="s"
              icon-name="close"
              class-name="text-carrotOrange mx-auto self-center"
            />
          </div>
          <div class="flex flex-col gap-2">
            <span class="font-semibold text-base text-darkPurple leading-5">
              How would you like to bulk moderate?
            </span>
            <div class="flex justify-between items-center">
              <div class="flex flex-col gap-2">
                <RadioButton
                  col-span="col-span-12"
                  class="text-sm text-darkPurple radio_button"
                  space-between="mr-2 my-3"
                  text-size="bg-green text-green"
                  v-model="calculator"
                  :options="calcOptions"
                  :rules="['required']"
                />
                <span>{{ errors[0] }}</span>
              </div>
              <div
                class="font-semibold text-darkPurple flex justify-start items-center gap-2 -mt-5"
              >
                <p
                  class="font-normal text-sm leading-6 tracking-wider text-jet"
                >
                  By
                </p>
                <TextInput
                  type="number"
                  class="text-base text-darkPurple w-36"
                  placeholder="Score"
                  v-model.number="score"
                  :rules="['required']"
                />
                <span>{{ errors[1] }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="flex justify-start items-center gap-2 text-darkPurple">
          <Icon class-name="w-3 h-3" size="s" icon-name="sort_size" />
          <span class="font-normal text-sm leading-6">All Functions</span>
          <Icon class-name="w-3 h-3" size="s" icon-name="downArrow" />
        </div>
      </template>
      <template>
        <div class="w-full mt-5">
          <SearchInput placeholder="Search" class="search text-carrotOrange" />
        </div>
      </template>
      <template>
        <div class="flex justify-between items-center my-5">
          <span class="font-normal text-xs leading-5 text-jet">
            {{ employeeIds.length }} Selected
          </span>
          <div class="flex justify-start items-center gap-2">
            <Checkbox
              label="Select All"
              checkbox-size="width: 16px; height: 16px;"
              label-style="color: #333333; font-size: 16px font-weight: 400; line-height: 120%"
              v-model="allChecked"
              @change="isAllChecked"
            />
          </div>
        </div>
      </template>
      <template>
        <div v-for="moderation in payload" :key="moderation.id">
          <div class="flex justify-between items-center">
            <div class="w-full flex flex-col gap-2">
              <div class="flex justify-start items-start gap-2">
                <div class="flex">
                  <img
                    :src="moderation.photo"
                    alt="user photo"
                    v-if="moderation.photo !== 'null'"
                    class="w-12 h-12"
                  />
                  <div
                    class="text-blueCrayola w-12 h-12 flex justify-center border text-center font-semibold pt-1"
                    v-else
                  >
                    {{ $getInitials(`${moderation.appraisee.name}`) }}
                  </div>
                </div>
                <div
                  class="flex flex-col whitespace-nowrap font-semibold leading-5"
                >
                  <span class="text-darkPurple capitalize text-base">
                    {{ moderation.appraisee.name }}
                  </span>
                  <span class="text-romanSilver uppercase text-xs">
                    {{ moderation.functions.department }} •
                    {{ moderation.functions.designation }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex justify-start items-center gap-2">
              <span>{{ errors[2] }}</span>
              <Checkbox
                checkbox-size="width: 16px; height: 16px;"
                @change="isChecked(moderation.id)"
                :value="moderation.id"
                v-model="employeeIds"
                :rules="['required']"
              />
            </div>
          </div>
          <div class="border-b border-romanSilver border-dashed mx-5 my-1" />
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
import { ValidationObserver } from "vee-validate";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Checkbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import RightSideBar from "@/components/RightSideBar";
import Icon from "@/components/Icon";

export default {
  components: {
    ValidationObserver,
    SearchInput,
    Icon,
    RightSideBar,
    Checkbox,
    RadioButton,
    TextInput,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      isOpen: false,
      employeeIds: [],
      allChecked: false,
      moderations: [],
      calculator: "",
      score: "",
      calcOptions: [
        { value: "increase", name: "Increase selected employee scores" },
        { value: "decrease", name: "Reduce selected employee scores" },
      ],
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    isAllChecked() {
      this.employeeIds = [];
      if (this.allChecked) {
        this.payload.forEach((moderation) => {
          this.employeeIds.push(moderation.id);
        });
      } else {
        this.employeeIds = [];
      }
    },
    isChecked(value) {
      this.allChecked = false;
      if (!_.findIndex(this.employeeIds, value)) {
        this.employeeIds.push(value);
      }
    },
    submit() {
      const payload = {
        moderationIds: this.employeeIds,
        orgId: this.$orgId,
        score: this.score,
        calculator: this.calculator,
      };

      this.$_postBulkCeoModeration(payload)
        .then(() => {
          this.$toasted.success("CEO moderation done successfully", {
            duration: 5000,
          });
          this.$emit("close", true);
          this.isOpen = !this.isOpen;
        })
        .catch(() => {
          this.$toasted.error("Error in performing moderation", {
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
/* .radio_button:checked span {
    background-color: rgb(37 99 235) !important;
    color: rgb(37 99 235) !important;
  } */
</style>